export default class Accordion {
  constructor(rootNode) {
    this.root = rootNode;
    this.initAccordion();

    const anchoredItem = this.getAnchoredItem();
    if(anchoredItem){
      setTimeout(() => {
        this.openAnchoredItem(anchoredItem);
        this.scrollToAnchoredItem(anchoredItem);
      }, 50)
    }
  }

  getAnchoredItem(){
    const urlString = window.location.href; 
    const url = new URL(urlString);
    const id = url.searchParams.get("faq");
    const anchoredItem = document.querySelector('#' + id);

    if(!anchoredItem || !this.root.contains(anchoredItem) || !anchoredItem.classList.contains('accordion-item')){
      return null;
    }

    return anchoredItem;
  }

  openAnchoredItem(anchoredItem){
    anchoredItem.querySelector('button').click();
  }

  scrollToAnchoredItem(anchoredItem){
    const baseOffset = -100;
    const headerHeight = - document.querySelector('header.Header').getBoundingClientRect().height; // Navigation + Notifications
    const y = anchoredItem.getBoundingClientRect().top + window.scrollY + baseOffset + headerHeight;

    window.scrollTo({top: y, behavior: 'smooth'});
  }

  initAccordion() {
    const buttons = this.root.querySelectorAll('.accordion-header');

    buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        const parent = button.parentElement;

        const isOpen = button.classList.contains('active');
        button.classList.toggle('active');

        const content = parent.querySelector('.accordion-content');
        const height = isOpen ? 0 : content.scrollHeight;

        content.style.setProperty('height', `${height}px`);
      });
    });
  }
}
